import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import Layout from "~/components/Layout";
import { Colors } from "~/components/ThemeVariables";

const Page = styled.div`
    display: flex;
    justify-content: center;
    padding: 50px 30px 100px;
    color: ${Colors.WHITE};
`;

const Container = styled.div`
    max-width: 1248px;
`;

const Title = styled.h1`
    font-size: 24px;
    margin-bottom: 24px;
`;

const Subheader = styled.h3`
    text-decoration: underline;
    margin: 24px 0px 18px;
`;

const Date = styled.p`
    margin-top: 24px;
    font-weight: 600;
`;

const TermsPage = (): JSX.Element => (
    <>
        <Helmet>
            <title>dClimate - Terms of Condition</title>
            <meta
                name="description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />
            <meta name="og:title" content="dClimate - Terms of Condition" />
            <meta
                property="og:description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />
            <meta
                name="twitter:title"
                content="dClimate - Terms of Condition"
            />
            <meta
                name="twitter:description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />
        </Helmet>
        <Layout>
            <Page>
                <Container>
                    <Title>Terms of Use</Title>
                    <p>
                        By accessing the dClimate Inc. websites and any page
                        thereof, you agree to be bound by these terms of use and
                        to comply with any applicable laws and regulations. If
                        you do not agree to these Terms of Use, do not proceed
                        to further pages associated with dClimate Inc.
                        <br />
                        <br />
                        dClimate Inc. reserves the right to vary or amend these
                        Terms of Use at any time and dClimate Inc. is under no
                        obligation to inform you about such variations of
                        amendments. The current version of the Terms of Use will
                        govern the services on dClimate Inc. websites and will
                        always be available on the dClimate Inc. websites. By
                        continuing to access or use these dClimate Inc. websites
                        after any changes become effective, you agree to be
                        bound by the revised Terms of Use. Please visit these
                        Terms of Use on a regular basis to ensure that you gave
                        read the latest version. These Terms of Use were updated
                        the last time on the date indicated at the end of the
                        Terms of Use.
                    </p>
                    <Subheader>
                        Local Access Restrictions and Additional Local
                        Information
                    </Subheader>
                    <p>
                        dClimate Inc. sites and all content and functionality
                        contained within them are not directed to or intended
                        for use by any person resident or located in any country
                        where the distribution of such content or functionality
                        is contrary to the laws, rules, or regulations of such
                        country. The same applies to any person resident or
                        located in any country where such distribution is
                        prohibited without obtaining the necessary licenses or
                        authorizations and such licenses or authorizations have
                        not been obtained. If any such local access restriction
                        applies to you, you are not authorized to access the
                        relevant dClimate Inc. site or use any of the content on
                        it.
                    </p>
                    <Subheader>
                        Content Not to Be Construed as a Recommendation or
                        Determination of Suitability
                    </Subheader>
                    <p>
                        The content of these websites is prepared for
                        informational purposes only without regard to any
                        particular user&apos;s investment objectives or
                        financial situation. Content on these websites does not
                        constitute a recommendation to enter into a particular
                        transaction nor a representation that any product
                        alluded to or described is suitable or appropriate for
                        the user. Any investment related to content on these
                        websites would involve significant risk given the
                        speculative nature of financial products related to
                        dClimate Inc.
                    </p>
                    <Subheader>Forward-looking Statements</Subheader>
                    <p>
                        Certain statements and illustrations contained herein
                        are forward-looking. These statements (including as to
                        plans, objectives, targets, and trends) and
                        illustrations provide current expectations of future
                        events based on certain assumptions and include any
                        statement that does not directly relate to a historical
                        fact or current fact.
                        <br />
                        <br />
                        Forward-looking statements typically are identified by
                        words or phrases such as “anticipate”, “assume”,
                        “believe”, “continue”, “estimate”, “expect”, “foresee”,
                        “intend”, “may increase”, “may fluctuate” and similar
                        expressions, or by future or conditional verbs such as
                        “will”, “should”, “would” and “could”. These
                        forward-looking statements involve known and unknown
                        risks, uncertainties and other factors, which may cause
                        the dClimate Inc.&apos;s actual results of operations,
                        financial condition, solvency ratios, capital or
                        liquidity positions or prospects to be materially
                        different from any future results of operations,
                        financial condition, solvency ratios, capital or
                        liquidity positions or prospects expressed or implied by
                        such statements or cause dClimate Inc. to not achieve
                        its published targets.
                    </p>
                    <Subheader>No Warranties Made as to Content</Subheader>
                    <p>
                        dClimate Inc. makes no warranty, express or implied,
                        concerning the content of these websites. All
                        information provided on this website is presented “as
                        is.” dClimate Inc. expressly disclaims any implied
                        warranty of merchantability or fitness for a particular
                        purpose, including any warranty for the use or the
                        results of the use of the contents of these websites
                        with respect to its correctness, quality, accuracy,
                        completeness, reliability, performance, or timeliness.
                        The content of these websites is believed to be accurate
                        and is obtained from sources, which Arbol believes to be
                        reliable. However, Arbol has not verified this
                        information and does not guarantee its accuracy,
                        completeness, fairness or timeliness, and the
                        information should not be relied on as such. As such,
                        the user of these websites acknowledges and agrees that
                        dClimate Inc., its advisers, consultants, shareholders,
                        and other affiliated persons are not responsible for and
                        are not making any representations, guarantees or
                        warranties regarding, the accuracy, completeness or
                        fairness of the information contained on these websites.
                    </p>
                    <Subheader>
                        Content Not to Be Construed as a Solicitation or
                        Offering
                    </Subheader>
                    <p>
                        The content of these websites should not be construed as
                        a solicitation, recommendation or offer to buy or sell
                        any security, financial product, or instrument. Any
                        business dealings arising from content on these websites
                        will be conducted in accordance with all applicable laws
                        and regulations. Any potential dealings in securities
                        related to content on these websites will neither be
                        registered under the Securities Act of 1933, nor will
                        they be registered or qualified under securities laws of
                        any jurisdiction, and such securities may not be offered
                        or sold in the United States except pursuant an
                        exemption from, or in a transaction not subject to, the
                        registration requirements of the Securities Act of 1933
                        and in compliance with state securities laws.
                    </p>
                    <Subheader>WTHR Tokens</Subheader>
                    <p>
                        WTHR Tokens have not been and will not be registered
                        under the Securities Act of 1933, as amended (the
                        “Securities Act”), or any other law or regulation
                        governing the offering, sale, or exchange of securities
                        in the United States or any other jurisdiction. The
                        offering of WTHR Tokens will be made, if any, (1) inside
                        the United States to “accredited investors” (as defined
                        in Section 501 of the Securities Act) who are U.S.
                        persons (as defined in Section 902 of Regulation S under
                        the Securities Act) in reliance on Regulation D under
                        the Securities Act and (2) outside the United States to
                        non-U.S. person in reliance on Regulation S. Subject to
                        certain limited exceptions, WTHR token purchasers will
                        be required to maintain their WTHR tokens until the
                        first anniversary of the issuance of the WTHR Tokens.
                    </p>
                    <Subheader>
                        Not All Risks Are Disclosed; Private Advisors Should Be
                        Consulted
                    </Subheader>
                    <p>
                        Many of the products described on these websites involve
                        significant risks, and the user of this website should
                        not enter into any transactions unless he has fully
                        understood all such risks and independently determined
                        such transactions are appropriate to him. Any discussion
                        of these risks contained on this website with respect to
                        any product should not be considered to be a disclosure
                        of all risks or a complete discussion of the risks that
                        are mentioned. Users of this website should neither
                        construe any material on these websites as business,
                        financial, investment, hedging, trading, legal,
                        regulatory, tax, or accounting advice nor make the
                        contents of this website the primary basis for any
                        investment decisions. Users are encouraged to consult
                        his business advisor, attorney, and tax and accounting
                        advisors concerning any contemplated transactions. In
                        addition, content of this website has not been approved
                        by an authorized person within the meaning of FSMA.
                        Reliance on the information contained on these websites
                        for the purposes of engaging in any investment activity
                        may expose the investor to a significant risk of losing
                        all of the property or assets invested. In the United
                        Kingdom, any person who is in any doubt about the
                        investment to which these websites relate should consult
                        a person duly authorized for the purposes of FSMA who
                        specializes in the acquisition of shares and other
                        securities.
                    </p>
                    <Subheader>
                        Content Not a Solicitation, Offer, or Promotion of
                        Insurance Product
                    </Subheader>
                    <p>
                        The content on these websites should not be construed as
                        a solicitation, offer, or promotion of an insurance
                        product associated with dClimate Inc. or any other
                        insurance company, broker, or agent. It is not intended
                        to create public interest in, induce the recipient to
                        purchase, increase, modify, reinstate, borrow on,
                        surrender, replace or retain any financial or insurance
                        product or service associated with dClimate Inc. By
                        using these websites, user affirms content does not
                        constitute the foregoing activities and agrees that any
                        belief on the part of user that content does constitute
                        foregoing activities is misconstrued by the user.
                    </p>
                    <Subheader>No Liability</Subheader>
                    <p>
                        Neither dClimate Inc., the Service Provider, any
                        third-party providing content, nor any of their
                        respective affiliates, directors, employees, advisors or
                        agents will be liable for any indirect, special,
                        incidental, consequential, punitive or exemplary loss,
                        liability or costs, whether arising from negligence,
                        breach of contract or otherwise and regardless whether
                        the damage was foreseeable or not. Neither dClimate
                        Inc., the Service Provider, any third-party providing
                        content, nor any of their respective affiliates,
                        directors, employees, advisors or agents will be liable
                        for any loss of profits, business or data. Neither
                        dClimate Inc., the Service Provider, any third party
                        providing content, nor any of their respective
                        affiliates, directors, employees, advisors or agents
                        will be liable for any damage or loss (especially loss
                        of data, alteration of data, or downtime) that may be
                        caused to any equipment or software due to any viruses,
                        defects or malfunctions in connection with the access or
                        use of any dClimate Inc. website or downloads from any
                        dClimate Inc. website (whether based on manual access,
                        subscriptions, RSS-feeds or otherwise).
                    </p>
                    <Subheader>Governing Law</Subheader>
                    <p>
                        These Terms of Use shall be governed by and interpreted
                        in accordance with the law of New York, and dClimate
                        Inc. and you each submit irrevocably to the exclusive
                        jurisdiction of the courts of the State of New York.
                    </p>
                    <Subheader>Severability</Subheader>
                    <p>
                        If a provision of these Terms of Use is or becomes
                        illegal, invalid or unenforceable in any country, that
                        will not affect the legality, validity or enforceability
                        in that country of any other provision or in other
                        jurisdictions of that or any other provisions of these
                        Terms of Use.
                    </p>
                    <Subheader>Additional Terms</Subheader>
                    <p>
                        Certain pages or areas on dClimate Inc. websites may
                        contain additional terms, conditions, disclosures or
                        disclaimers. In the event of a conflict between these
                        Terms of Use and these provisions, the additional
                        provisions will govern for those pages or areas.
                    </p>
                    <Subheader>Intellectual Property Rights</Subheader>
                    <p>
                        Intellectual Property Rights in the dClimate Inc.
                        websites and content related available through them
                        belong to dClimate Inc. and/or to the Service Provider
                        or other third parties. You do not acquire, by virtue of
                        your use of the dClimate Inc. site, any rights, title or
                        interest in any of the Intellectual Property Rights on
                        these websites or in the dClimate Inc. For the purpose
                        of these Terms of Use, “Intellectual Property Rights”
                        includes, but is not limited to: (i) copyright, patents,
                        database rights and rights in trademarks, designs,
                        know-how and registered or non-registered confidential
                        information; (ii) applications for registration and the
                        right to apply for registration for any of these rights;
                        and (iii) all other intellectual property rights and
                        equivalent or similar forms of protection existing
                        anywhere in the world.
                        <br />
                        <br />
                        Individual pages and/or sections of the dClimate Inc.
                        website and its content may be printed or saved for
                        personal or internal use only. Such printouts or
                        savings, however, must retain all applicable copyright
                        or other proprietary notices. You must not modify, copy,
                        transmit, distribute, display, reproduce, publish,
                        perform, license, frame, create derivative works from,
                        transfer or use in any other way for commercial or
                        public purposes in whole or in part any Content obtained
                        from an dClimate Inc. website without the prior written
                        permission of the owner of the relevant Intellectual
                        Property Rights (whether that be dClimate Inc., the
                        Service Provider or another third party). It is
                        prohibited to systematically retrieve content from the
                        dClimate Inc. website to create or compile, directly or
                        indirectly, a collection, compilation, database or
                        directory (whether through robots, spiders, automatic
                        devices or manual processes) without written permission
                        from dClimate Inc.
                        <br />
                        <br />
                        The copyright in some content available on dClimate Inc.
                        belongs to third parties (“Third Party Content”). You
                        must check copyright notices on or in respect of
                        individual Third-Party Content.
                    </p>
                    <Date>Date of Terms of Use: December 27, 2021</Date>
                </Container>
            </Page>
        </Layout>
    </>
);

export default TermsPage;
